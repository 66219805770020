<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img
          contain
          :lazy-src="require('../assets/Military-baner-lazy.webp')"
          :src="require('../assets/Military-baner.webp')"
          id="logo"
        />
      </v-col>
      <v-row>
        <v-col>
          <h1>إدارة التربية العسكرية بجامعة الاسوان</h1>
          <div dir="RTL">
            <P class="text-justify">
              نظرا للظرف الإستثنائي الذى تمر بة البلاد والعالم أجمع (إنتشار
              فيروس كورونا المستجد). والذى أدى إلى توقف دورات التربية العسكرية
              بالجامعة. وذلك فقد صدق السيد/ القائد العام للقوات المسلحة وزير
              الدفاع والإنتاج الحربى على عقد دورات التربية العسكرية لطلبة
              الجامعات بعد تمام التنسيق مع المجلس الأعلى للجامعات كالاتى
            </P>
            <p class="font-weight-bold text-justify">
              أولاً : سيتم إتاحة المادة العلمية بدورات التربية العسكرية للطلاب
              على
              <a href="http://elc.aswu.edu.eg/"
                >موقع التعلم الإلكترونى لجامعة اسوان</a
              >
              طبقا للموضوعات الأتية:
            </p>
            <v-card
              class="mb-12 mx-auto"
              :max-width="maxwidth"
              tile
              elevation="24"
            >
              <v-list-item>حروب الجيل الرابع .</v-list-item>
              <v-list-item>
                المخدرات وتأثيرها السلبى على الشباب وأسلوب الوقاية منها .
              </v-list-item>
              <v-list-item
                >الشائعات ودورها السلبى على الفرد والمجتمع .</v-list-item
              >
              <v-list-item
                >شبكة الإنترنت ومخاطر موقع التواصل الإجتماعى .</v-list-item
              >
              <v-list-item
                >دور القوات المسلحة فى التنمية الشاملة للدوله.</v-list-item
              >
            </v-card>
            <p class="font-weight-bold text-justify">
              ثانياً : قيام الطلاب بتقديم ورقة بحثية {كإختبار عملى مقيم (30)
              درجة} فى أحد الموضوعات الأتية والتي تحدد للطالب
            </p>
            <v-card
              class="mb-12 mx-auto"
              :max-width="maxwidth"
              tile
              elevation="24"
            >
              <v-list-item
                >دور الدولة والأجهزة المعنية لمجابهة فيروس كورونا المستجد
                .</v-list-item
              >
              <v-list-item
                >تأثير فيروس كورونا على الإقتصاد العالمى والمصرى .</v-list-item
              >
              <v-list-item
                >دور المجتمع المدنى فى مجابهة فيروس كورونا .</v-list-item
              >
              <v-list-item
                >الأثر السلبى للشائعات خلال إدارة الأزمة وأسلوب مجابهتها
                .</v-list-item
              >
              <v-list-item
                >المخدرات وتأثيرها السلبي علي الشباب وأسلوب الوقاية
                منها</v-list-item
              >
              <v-list-item>حرب الجيل الرابع .</v-list-item>
              <v-list-item
                >شبكة الانترنت ومخاطر مواقع التواصل الإجتماعى .</v-list-item
              >
            </v-card>
            <p class="font-weight-bold text-justify">
              ثالثاً : عقد إختبار {مقيم (70) درجة} لمادة التربية العسكرية
            </p>
            <p class="font-weight-bold text-justify">
              رابعاً : وقد تم إنشاء موقع إلكترونى خاص
              <a href="/military/register">للتسجيل بالتربية العسكرية</a>
            </p>
            <p class="font-weight-bold text-justify">
              خامساً : تقديم البحوث للطلبة المسجلون بدورات التربية العسكرية من
              خلال
              <a href="https://elc.aswu.edu.eg/">
                موقع التعلم الإلكترونى لجامعة اسوان
              </a>
            </p>
            <p class="font-weight-bold text-justify">
              سادساً :سيتم الإعلان عن موعد الإختبار النهائى لمادة التربية
              العسكرية للطلبه المسجلون بالتربية العسكرية على
              <a href="https://elc.aswu.edu.eg/">
                موقع التعلم الإلكترونى لجامعة اسوان
              </a>
            </p>
          </div>
          <v-row dir="rtl">
            <v-col>
              <a href="/military/register/">
                <v-btn color="primary" elevation="24">
                  تسجيل التربية العسكرية
                </v-btn>
              </a>
            </v-col>
            <v-col>
              <a href="/military/accepted.html">
                <v-btn color="primary" elevation="24">
                  عرض المقبولين بالتربية العسكرية
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "military",

  data: () => ({
    //data
  }),
  computed: {
    maxwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 350;
        case "sm":
          return 450;
        case "md":
          return 550;
        case "lg":
          return 650;
        case "xl":
          return 850;
        default:
          return 300;
      }
    }
  }
};
</script>
